// any JS you import will output into a single js file (frontend.js in this case)
import 'bootstrap/dist/js/bootstrap.min.js';


// any CSS you import will output into a single css file (frontend.css in this case)
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'hint.css/hint.min.css';
import './styles/screen.scss';
import './styles/main.css';


// create global $ and jQuery variables to fix error "$ not defined"
// https://stackoverflow.com/questions/48971680/webpack-encore-is-not-defined
// todo: autoProvidejQuery is enabled, but still we need this workaround. why? may be it is related to "defer" script load?
const $ = require('jquery');
global.$ = global.jQuery = $;

import './javascript/re3data.js';